import { template as template_90995d51416746fa85f4217c2b8279fa } from "@ember/template-compiler";
const FKControlMenuContainer = template_90995d51416746fa85f4217c2b8279fa(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
