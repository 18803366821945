import { template as template_75f579dba056474bae3dc56b1e0d8628 } from "@ember/template-compiler";
const SidebarSectionMessage = template_75f579dba056474bae3dc56b1e0d8628(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
