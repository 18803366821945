import { template as template_37db56a3c819471d8d9a327f732d4a28 } from "@ember/template-compiler";
const FKText = template_37db56a3c819471d8d9a327f732d4a28(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
